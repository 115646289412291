<template>
  <div class="content-container stats-wrapper">
    <StatBox v-for="(stat,index) in stats.stats"
             :key="index"
             :title="stat.title"
             :values="stat.values"></StatBox>

    <div class="poll-stats-wrapper">
      <h1 class="page-section-title section-title">{{ $t('STATS.OVERVIEW') }}</h1>
      <div class="poll-stats-content">
        <PollStatsBox v-for="mission in stats.pollStats"
                      :key="mission.missionSlideId"
                      :item="mission"/>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_STATS } from '@/store/modules/helpdesk/actions'
import StatBox from '../../../components/elements/stats/StatBox.vue'
import PollStatsBox from '../../../components/elements/stats/PollStatsBox.vue'
import { useStore } from 'vuex'
import { ref, computed, onMounted, onUnmounted } from 'vue'

export default {
  name: 'Stats',
  components: { StatBox, PollStatsBox },
  setup(props) {
    let callCount = 0
    let interval = null
    const maxCallCount = 100
    const timeBetweenUpdate = 30

    const store = useStore()

    const stats = computed(() => store.getters.getStats)

    getStats()
    function getStats() {
      callCount += 1
      if (callCount >= maxCallCount) {
        clearInterval(interval)
      }
      store.dispatch(GET_STATS)
    }

    ///Uncomment these if you want it to refresh every 30 seconds.
/*    onMounted(() => {
      interval = setInterval(getStats, timeBetweenUpdate * 1000)
    })*/

/*    onUnmounted(() => {
      clearInterval(interval)
    })*/

    return {
      stats,
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
.stats-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: rem(50);
}

.poll-stats-content {
  display: flex;
  gap: rem(22) rem(32);
  flex-flow: row wrap;
  align-items:flex-start;
  align-content:flex-start;
}

</style>
